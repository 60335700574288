import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';
import { hydrate } from 'react-dom';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(document.getElementById('root'));
if(rootElement.hasChildNodes()){
    hydrate(
        <React.StrictMode>
            <MantineProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                    </Routes>
                </BrowserRouter>
            </MantineProvider>
        </React.StrictMode>,
        rootElement
    );
}else{
    root.render(
        <React.StrictMode>
            <MantineProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<App />} />
                    </Routes>
                </BrowserRouter>
            </MantineProvider>
        </React.StrictMode>,
        rootElement
    );
}
