import './Faq.css'
import { Accordion } from '@mantine/core'
import { faqs } from '../assets/data'
import begambleawareicon from '../assets/images/be-gamble-ware.png'


const Faq = () => {
    return (
        <section className='faq-section' id='faq'>
            <div className='faq-container'>
                <h1 className='faq-heading'>Frequently Asked Questions</h1>
                <div className='faq-container'>
                    <Accordion chevronPosition="left" variant='contained' color='#fff'
                        styles={{
                            control: {
                                backgroundColor: '#242325',
                                color: 'var(--primary-color)',
                                border: '0.8px solid #fff',
                                fontSize: '1rem',
                                fontWeight: '600',

                                ':hover': {
                                    backgroundColor: 'var(--primary-color)',
                                    color: "#000"
                                }
                            },
                            panel: {
                                backgroundColor: '#242325',
                                color: '#fff',
                                border: '0.8px solid #fff',
                                fontSize: '1rem',
                                fontWeight: '600'
                            },
                            
                        }}
                    >
                        {faqs.length !==0 && faqs.map((faq)=> {
                            return (
                                <Accordion.Item value={faq.id} key={faq.id}>
                                    <Accordion.Control>{faq.question}</Accordion.Control>
                                    <Accordion.Panel>{faq.answer}</Accordion.Panel>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
                <div class="gamble-aware">
                    <div class="text1">
                        You must be 18 years old or over to use this site. Please bet responsibly.						
                    </div>
                    <div class="image">
                        <img src={begambleawareicon} class="attachment-large size-large wp-image-39" alt=''/>
                    </div>
                    <div class="text3">
                        BeGambleAware.org aims to promote responsibility in gambling. They provide information to help you make informed decisions about your gambling.
                        Call the National Gambling Helpline 0808 8020 133 8am to midnight, 7 days a week.						
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq;