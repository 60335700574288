import "./partnerList.css";
import casino500icon from "../assets/images/500casino.webp";
import bet365icon from "../assets/images/bet365.png";
import betfairicon from "../assets/images/betfair.png";
import sportsbeticon from "../assets/images/sportsbet.png";
import stake from "../assets/images/stake.png";

export default function Partners() {

    return (
        <>
            <div className="partner-list-container" id="bookmakers">
                <div className="partner-text-container">
                    <h1 className="partner-heading">
                        Bookmakers<br className="partner-line-break"/> we use
                    </h1>
                    {/* <button className="partner-button">
                        Join now
                    </button> */}
                </div>
                <div className="partner-lists">
                    <div class="slider">
                        <div class="slide-track">
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={casino500icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={bet365icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={betfairicon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={sportsbeticon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={stake} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={casino500icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={bet365icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={betfairicon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={sportsbeticon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={stake} height="100" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="slider ">
                        <div class="slide-track track2">
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={betfairicon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={sportsbeticon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={stake} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={casino500icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={bet365icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={betfairicon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={sportsbeticon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={stake} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={casino500icon} height="100" alt="" />
                                </div>
                            </div>
                            <div class="slide-wrapper">
                                <div class="slide">
                                    <img src={bet365icon} height="100" alt="" />
                                </div>                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}