import "./hero.css";
import heroImg from '../assets/images/hero-image.jpg'
export default function Hero(){

    return (
        <section id="hero">
            <div className="exchange-currency-box">
                <img src={heroImg} alt="hero" className="hero-image"/>
            </div>
        </section>
    )
}