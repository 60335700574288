import "./featuresCard.css";


export default function FeaturesCard({pos,heading,subHeading,icon}) {
    return(
        <div className={`feature-crd ${pos}`}>
            <div className="heading-container">
                <h1 className="feature-heading">
                    {heading}
                </h1>
                <p className="feature-sub-heading">
                    {subHeading}
                </p>
            </div>
            <div className="feature-icons">
                {icon}
            </div>
        </div>
    )
}