import { MantineProvider } from "@mantine/core";
import "./App.css";
// import AboutUs from './components/AboutUs';
import ResultBoard from "./components/ResultBoard";
import Faq from "./components/Faq";
import Features from "./components/Features";
import Footer from "./components/Footer";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Partners from "./components/Partners";
import BuyNow from "./components/BuyNow";

function App() {
    return (
        <MantineProvider theme={{colorScheme:'dark'}}>
            <div className="wrap">
                <div className="App">
                    <div className="container1">
                        <Header />
                    </div>
                    <Hero />
                    <div className="container1">
                        <Features />
                        <ResultBoard />
                    </div>
                </div>
                <BuyNow />
                <Gallery />
                <Partners />
                <Faq />
                <Footer />
            </div>
        </MantineProvider>
    );
}

export default App;
