import "./header.css";
import { IoMenu } from "react-icons/io5";
import logo from "../assets/images/logo.jpg";
import { ActionIcon, Drawer } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

export default function Header (){
    const [opened,{open,close}] = useDisclosure(false);
    return(
        <section id="header">
            <div className="handburger-menu-container">
                <ActionIcon onClick={open}>
                    <IoMenu className="menu"/>  
                </ActionIcon>
            </div>
            <Drawer opened={opened} onClose={close} className="mobile-navbar">
                <p>
                    <a href="#features" onClick={close}>Features</a>
                </p>
                <p>
                    <a href="#results" onClick={close}>Results</a>
                </p>
                <p>
                    <a href="#gallery" onClick={close}>Gallery</a>
                </p>
                <p>
                    <a href="#bookmakers" onClick={close}>Bookmakers</a>
                </p>
                <p>
                    <a href="#faq" onClick={close}>FAQs</a>
                </p>
            </Drawer>
            <img className="header-logo" src={logo} alt="logo" />
            <div className="navigation-section">
                <p>
                    <a href="#features">Features</a>
                </p>
                <p>
                    <a href="#results">Results</a>
                </p>
                <p>
                    <a href="#gallery">Gallery</a>
                </p>
                <p>
                    <a href="#bookmakers">Bookmakers</a>
                </p>
                <p>
                    <a href="#faq">FAQs</a>
                </p>
            </div>
        </section>
    )
}