import './BuyNow.css'
import paymenticons from '../assets/images/paymenticons.png'
import { FaCheckCircle } from "react-icons/fa";

const BuyNow = () => {
    return (
        <section id="buy-now">
            <div class="buy-now">
                <div class="buy-now-title">
                    <h3 class="editable">Buy Now</h3>
                </div>
                <div class="buy-now-content">
                    <div class="top-cards">
                        <div class="buynow-card vertical">
                            <div class="heading">
                                <h5 class="editable">2 MONEY MAKER VIP TIPS</h5>
                            </div>
                            <div class="body">
                                <h6 class="editable">2 Tips</h6>
                                <p class="editable price">$62</p>
                            </div>
                            <div class="action">
                                <a
                                    href='https://pxl.to/62sub'
                                    class="buy-btn editable"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    BUY NOW
                                </a>
                                <img class="editable" src={paymenticons} alt="payment methods"/>
                            </div>
                            <div class="extra-info">
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Receive tips on your email
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    12/7 support
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Secure Payment Page
                                </p>

                            </div>
                        </div>
                        <div class="buynow-card vertical">
                            <div class="heading">
                                <h5 class="editable">Soccer GPT Monthly</h5>
                            </div>
                            <div class="body">
                                <h6 class="editable">Access</h6>
                                <p class="editable price">$200</p>
                            </div>
                            <div class="action">
                                <a
                                    href='https://pxl.to/monthlygpt'
                                    class="buy-btn editable"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    BUY NOW
                                </a>
                                <img class="editable" src={paymenticons} alt="payment methods"/>
                            </div>
                            <div class="extra-info">
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Receive tips on our private discord channel
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    12/7 support
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Secure Payment Page
                                </p>

                            </div>
                        </div>
                        <div class="buynow-card vertical">
                            <div class="heading">
                                <h5 class="editable">Soccer GPT Lifetime</h5>
                            </div>
                            <div class="body">
                                <h6 class="editable">Access</h6>
                                <p class="editable price">$350</p>
                            </div>
                            <div class="action">
                                <a
                                    href='https://pxl.to/lifetimegpt'
                                    class="buy-btn editable"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    BUY NOW
                                </a>
                                <img class="editable" src={paymenticons} alt="payment methods"/>
                            </div>
                            <div class="extra-info">
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Receive tips on our private discord channel
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    12/7 support
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Secure Payment Page
                                </p>

                            </div>
                        </div>
                        {/* <div class="buynow-card vertical">
                            <div class="heading">
                                <h5 class="editable">1 Month</h5>
                            </div>
                            <div class="body">
                                <h6 class="editable">Access</h6>
                                <p class="editable price">$300</p>
                            </div>
                            <div class="action">
                                <a
                                    href='https://marylinmafiashop.sell.app/product/sniper-casino-method'
                                    class="buy-btn editable"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    BUY NOW
                                </a>
                                <img class="editable" src={paymenticons} alt="payment methods"/>
                            </div>
                            <div class="extra-info">
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Receive tips on your email
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    12/7 support
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Secure Payment Page
                                </p>

                            </div>
                        </div> */}
                    </div>
                    <div class="bottom-cards">
                        <div class="buynow-card horizontal">
                            <div class="heading">
                                <h5 class="editable">30 TIPS PACK</h5>
                            </div>
                            <div class="body horizontal">
                                <h6 class="editable">30 Tips</h6>
                                <p class="editable price">$450</p>
                            </div>
                            <div class="action">
                                <a 
                                    href='https://pxl.to/450sub'
                                    class="buy-btn editable"
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    BUY NOW
                                </a>
                                <img class="editable" src={paymenticons} alt="payment methods"/>
                            </div>
                            <div class="extra-info">
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Receive tips on your email
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    12/7 support
                                </p>
                                <p>
                                    <FaCheckCircle color='var(--primary-color)'/> &nbsp;
                                    Secure Payment Page
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


export default BuyNow