import "./Gallery.css"
import "./aboutUs.css";
// import AboutUs from "./AboutUs";
import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css";
import image1 from "../assets/images/image1.jpg";
import image2 from "../assets/images/image2.jpg";

export default function Gallery() {
    return (
        <div className="carousel-container" id="gallery">
            <section id="about-us">
                <div className="about-container">
                    <h1 className="about-heading">Gallery</h1>
                </div>
            </section>
            <div className='carousel-items' id="carousel-container">
                <Carousel touch={false}>
                    <Carousel.Item className="carousel-item">
                        <iframe title="video1" height={'100%'} width={'100%'} src="https://www.youtube.com/embed/sEF_vC15xtY?autoplay=1&mute=1&loop=1">
                        </iframe>
                    </Carousel.Item>
                    <Carousel.Item className="carousel-item">
                        <iframe title="video1" height={'100%'} width={'100%'} src="https://www.youtube.com/embed/LMnZkGwVR-Y?autoplay=1&mute=1&loop=1">
                        </iframe>
                    </Carousel.Item>
                    <Carousel.Item className="carousel-item">
                        <img
                            src={image1}
                            alt="Soccer gpt"
                        />
                    </Carousel.Item>
                    <Carousel.Item className="carousel-item">
                        <img
                            src={image2}
                            alt="graph"
                        />
                        <Carousel.Caption>
                            <p>Soccer GPT Simulation with last 100 bets sent</p>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        </div>
    )
}