import "./resultboard.css";
import { IoArrowBackOutline } from "react-icons/io5";
import { IoArrowForwardOutline } from "react-icons/io5";
import { Table, Text } from "@mantine/core";
import { getResults } from "../api/result";
import { useEffect, useState } from "react";
import moment from 'moment';
import { usePagination } from "@mantine/hooks";


export default function ResultBoard() {
    const [resultsData, setResultsData] = useState ([]);
    const [totalResults, setTotalResults] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const offset = 10;
    const pages = usePagination({
        total: Math.ceil(totalResults / offset),
        initialPage: 1,
        onChange: (page) => setCurrentPage(page)
    })
    const nextDisabled = currentPage === Math.ceil(totalResults / offset);
    const prevDisabled = currentPage === 1;
    const prevBtnClass = prevDisabled ? 'result-control prev-btn' : 'result-control prev-btn active'
    const nextBtnClass = nextDisabled ? 'result-control next-btn' : 'result-control next-btn active'
    useEffect(() => {
        const fetchResults = async () => {
            try {
                const results = await getResults();
                setResultsData(results.data);
                setTotalResults(results.data.length);
            } catch (error) {
                if (error.response) {
                    console.log(error.response.data.message)
                } else {
                    console.log(error.message)
                }
            }
        };
        fetchResults();
    }, []);

    const rows = resultsData.map((item) => {
        const outcomeClass = item.outcome === "Win" ? "win" : "loss";
        const parsedDate = new Date(item.date);
        const datestr = moment(parsedDate).format('YYYY-MM-DD');
        return (
            <tr key={item._id}>
                <td>{datestr}</td>
                <td>{item.league}</td>
                <td>{item.match}</td>
                <td>{item.tip_type}</td>
                <td>{item.odds}</td>
                <td className={outcomeClass}>{item.outcome}</td>
            </tr>
        )
    }).slice((currentPage - 1) * offset, currentPage * offset);
    
    return (
        <section id="results">
            <div className="result-nav-bar">
                <h1>Results</h1>
                <div className="result-controls">
                    <button className={prevBtnClass} onClick={prevDisabled ? undefined :()=>pages.previous()}>
                        <IoArrowBackOutline/>
                        Previous
                    </button>
                    <button className={nextBtnClass} onClick={nextDisabled ? undefined :()=>pages.next()}>
                        Next
                        <IoArrowForwardOutline />
                    </button>
                </div>
               
            </div>
            <div className="result-table">
                <Table align="center" horizontalSpacing={'xl'} verticalSpacing={'md'} highlightOnHover>
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>League</th>
                            <th>Match/Competitors</th>
                            <th>Tip Type</th>
                            <th>Odds</th>
                            <th>Outcome</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </Table>
            </div>
            <Stats results={resultsData}/>
        </section>
    )
}

const Stats = ({results}) => {
    const totalOddsSent = results.reduce((acc, curr) => acc + curr.odds, 0);
    const wonOdds = results.filter(result => result.outcome === 'Win').reduce((acc, curr) => acc + curr.odds, 0);
    const lostOdds = results.filter(result => result.outcome === 'Loss').reduce((acc, curr) => acc + curr.odds, 0);
    const oddsSuccessRate = (wonOdds / totalOddsSent) * 100;

    const totalBetsSent = results.length;
    const wonBets = results.filter(result => result.outcome === 'Win').length;
    const lostBets = results.filter(result => result.outcome === 'Loss').length;
    const successRate = (wonBets / totalBetsSent) * 100;
    return (
        <div className="stats">
            <Text size={'xl'} my={'sm'} align="center" weight={'bolder'} sx={{color:"#fff"}}>Stats</Text>
            <div className="stats-table">
                <Table align="right" horizontalSpacing={'sm'} verticalSpacing={'md'} highlightOnHover>
                    <tbody>
                        <tr>
                            <th></th>
                            <th>Total Sent</th>
                            <th>Won</th>
                            <th>Lost</th>
                            <th>Success % </th>
                        </tr>
                        <tr>
                            <th>Odds</th>
                            <td>{totalOddsSent.toFixed(2)}</td>
                            <td className="win">{wonOdds.toFixed(2)}</td>
                            <td className="loss">{lostOdds.toFixed(2)}</td>
                            <td className="rate">{oddsSuccessRate.toFixed(2)} %</td>
                        </tr>
                        <tr>
                            <th>Bets</th>
                            <td>{totalBetsSent}</td>
                            <td className="win">{wonBets}</td>
                            <td className="loss">{lostBets}</td>
                            <td className="rate">{successRate.toFixed(2)} %</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}