
// Faq questions and answers
export const faqs = [
    {
        id: "1",
        question: "What's the success rate/win rate of your betting tips ?",
        answer: "The confirmed success rate is around 75% to 80% for both the VIP daily picks tips and also for soccer GPT '' Daily '' tips, as well.",
    },
    {
        id: "2",
        question: "What's the refund policy if any ?",
        answer: "We do offer a no questions asked refund for the Daily Picks package only (2 tips for $62 USD) plan only, which is our TESTING plan geared specifically for those people who want to test the QUALITY of our VIP PREMIUM betting tips, then. For that plan after the 2 tips is sent if you are not in profit you can choose to keep receiving tips from us until YOU ARE IN PROFIT or to receive a no questions asked refund of your $62 USD, then. For all the other membership plans we do not offer any refunds and all sales are final for those.",
    },
    {
        id: "3",
        question:
            "What are the betting markets supported for your tips/memberships?",
        answer: "For Soccer GPT we support; Over/Under Goals, Odd/Even Goals, 1x2, Half-Time FullTime Tips, and Correct Scores. For VIP PREMIUM subscriptions we do offer 1x2, over/under goals, 1x2 + BTTS and BTTS + Over/Under Goals markets, then (All HIGH odds)",
    },
    {
        id: "4",
        question:
            "What are the average odds for your betting tips memberships?",
        answer: "The average odd is 2.04x on Soccer GPT, and sometimes/most of the times we go for odds around/above 3.00x with maximum odds sometimes reaching even the 6.00 range (For the vip picks) as well...",
    },
    {
        id: "5",
        question: "Where are the betting tips sent ?",
        answer: "For the VIP PREMIUM PICKS they are sent by email, only. And for Soccer GPT they are posted and tracked/updated for results automatically, on our private discord channel.",
    },
    {
        id: "6",
        question: "Do you GUARANTEE wins/results?",
        answer: "Although we have a CONFIRMED/VERIFIED/VERIFIABLE succcess rate so far which anyone can see by asking for accessing the inside of our private discord channel temporarily (when possible) and from the video proofs/video results posted by us on our youtube and telegram channels so far, we have however to say that sports betting is inherently random and anything/any outcome is possible. For example a BIG favorite team can take an unfortunate RED CARD or two, at any time during a match and then this red cards giving an unfair advantage to the underdog team, resulting on a totally UNEXPECTED loss for the favorite team. This kinds of '' jokes/misfortune '' can happen and change outcome of matches dramatically. This is just an example of the inherent '' anything is possible '' possibility for sports betting, so due to this possibilities we CANNOT and DO NOT guarantee results/wins on our services here. But we do offer VERIFIABLE / VERIFIED success rate/past results and a AFFORDABLE TESTING package under our VIP PREMIUM PICKS that anyone can use with a 100% money back guarantee or 100% success guarantee where we'll be sending you premium picks UNTIL YOU ARE IN PROFIT, then, period! That's our only guarantee, here !",
    },
    {
        id: "7",
        question: "How many times i can buy the test package ?",
        answer: "You are allowed to buy the testing package only ONCE per email/per person after the test is over and the 2 tips are sent you can only upgrade to the higher tiers then, not the testing package anymore."
    },
];