import "./footer.css";
import { FaLinkedinIn } from "react-icons/fa6";
import { RiDiscordFill } from "react-icons/ri";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import logo from '../assets/images/logo.jpg';
import { MdEmail } from "react-icons/md";


export default function Footer() {
    return (
        <section id="footer">
            <div className="main-footer">
                <div className="footer-container">
                    <div className="footer-info">
                        <img src={logo} alt="logo" />
                        <p>BEST AI BASED SOCCER BETTING TIPS IN THE WORLD.</p>
                    </div>
                    <div className="footer-contact">
                        <div className="company-support">
                            {/* <div className="company-container">
                                <p className="lightText">Company</p>
                                <p className="footer-bold-text">About</p>
                                <p className="footer-bold-text">Contact</p>
                            </div> */}
                            <div className="support-container">
                                <p className="lightText">Support</p>
                                <p className="footer-bold-text">
                                    <a href="#faq">FAQ</a>
                                </p>
                            </div>
                        </div>
                        <div className="footer-follow">
                            <p className="lightText">Follow Us</p>
                            <div className="cmpy-contacts">
                                {/* <div className="follow-us-contacts"><FaLinkedinIn className="fotter-icons"/></div> */}
                                {/* <div className="follow-us-contacts"><RiDiscordFill className="fotter-icons"/></div> */}
                                {/* <div className="follow-us-contacts"><FaTwitter className="fotter-icons"/></div> */}
                                <div className="follow-us-contacts">
                                    <a href="https://telegram.me/soccergpt">
                                        <FaTelegramPlane className="fotter-icons"/>
                                    </a>
                                </div>
                                <div className="follow-us-contacts">
                                    <a href="mailto:thomas@marylin.email">
                                        <MdEmail className="fotter-icons"/>
                                    </a>
                                </div>
                                {/* <div className="follow-us-contacts"><FaYoutube className="fotter-icons"/></div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <p className="footer-copyright lightText">Copyright © 2020 - 2024 SoccerGPT!</p>
            </div>
        </section>
    )
}