import "./features.css";
import FeaturesCard from "./FeaturesCard";
import { GoTrophy } from "react-icons/go";
import { TbShareOff } from "react-icons/tb";
import { FaSmile } from "react-icons/fa";
import { GiBrain } from "react-icons/gi";

const features = [
    {
        title: "Start being a WINNER",
        description: "Gain access to the most WANTED TIPS in the betting world, 20 TO 30 money maker bets shared per month on average.",
        icon: <GoTrophy/>
    },
    {
        title: "NO OVER SHARING OF BETS",
        description: "No OVER-SHARING of tips/bets daily that will just break your bank here, we send you only PREMIUM PICKS and one to TWO maximum bets per day",
        icon: <TbShareOff/>
    },
    {
        title: "SOCCER TIPS THAT WILL MAKE YOU SMILE",
        description: "On our service we send you daily, mostly heavy odds tips (above 2.00x) that win. Most of the times we go even higher than 2.00x odds.This tips will put a smile on your face.",
        icon: <FaSmile/>
    },
    {
        title: "NO MORE GUESS WORK",
        description: "No more guess work. Stop trying to pick winners on your own or subscribing to tipster services that blow your bankroll.",
        icon: <GiBrain/>
    }
]

const firstFeature = features[0];
const lastFeature = features[features.length - 1];

const middleFeatures = features.slice(1, features.length - 1);

export default function Features(){
    return (
        <section id="features">
            <FeaturesCard pos = "first" heading = {firstFeature.title} subHeading = {firstFeature.description} icon={firstFeature.icon}/>          
            {middleFeatures.map((feature, index) => (
                <FeaturesCard key = {index} pos = "middle" heading = {feature.title} subHeading = {feature.description} icon={feature.icon}/>
            ))}
            <FeaturesCard pos = "last" heading = {lastFeature.title} subHeading = {lastFeature.description} icon={lastFeature.icon}/>          
        </section>
    )
}