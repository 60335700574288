import axiosInstance from 'axios';

const axios = axiosInstance.create({
    baseURL: "https://soccergptapi-1-m9707715.deta.app",
});

export const getResults = async () => {
    const response = await axios.get("/result/get");
    return response.data;
};
